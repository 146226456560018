


































import { auth } from '@/plugins/firebase';
import { Component, Vue } from 'vue-property-decorator';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { AuthService } from '@/services/AuthService';

@Component
export default class Login extends Vue {
  email = '';
  password = '';
  showPassword = false;
  authService = new AuthService();

  handleLogin() {
    signInWithEmailAndPassword(auth, this.email, this.password)
      .then((result) => {
        if (!result.user.emailVerified) {
          this.authService.verifyEmail(result.user.uid);
        }
        this.$router.push({ name: 'Home' });
      })
      .catch(() => {
        this.$showError(this.$t('login.notSuccessful'));
      });
  }
}
